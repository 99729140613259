'use client'
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from "../../data/landing/data-landing.json";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import "./discover.css";

const Discover = () => {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  
    const [items, setItems] = useState(data.discover.items.map((item) => ({
      ...item,
      isActive: false,
    })));
  
    const handleClick = (index) => {
      const updatedItems = [...items];
      updatedItems[index].isActive = !updatedItems[index].isActive;
      setItems(updatedItems);
    };
  
    return (
      <>
        <div className="discover">
          <div className="discover__content" data-aos="fade-up">
            <h2 className="discover__title">{data.discover.title}</h2>
            <div className="discover__items">
              {items.map((item, index) => (
                <Accordion
                  className="discover__item"
                  key={index}
                  elevation={0}
                  expanded={item.isActive}
                  onChange={() => handleClick(index)}
                >
                  <AccordionSummary
                    className="discover__item__header"
                    aria-controls={`panel${item[2]}a-content`}
                    id={`panel${item[2]}a-header`}
                  >
                    <img
                      className={`discover__item__img ${item.isActive ? '' : 'iconAnimation'}`}
                      src={item.isActive ? data.discover.minus.src : data.discover.plus.src}
                      alt={item.isActive ? data.discover.minus.alt : data.discover.plus.alt}
                      data-aos
                    />
                    <h3 className="discover__item__title">{item[0]}</h3>
                  </AccordionSummary>
                  <AccordionDetails className="discover__item__textContainer">
                    <p className="discover__item__paragraph">{item[1]}</p>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Discover;
  