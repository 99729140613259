import React from 'react';
import Banner from './components/landing/banner';
import We from './components/landing/we';
import Discover from './components/landing/discover.jsx';
import Contact from './components/landing/contact';
import './landing.css';

const LandingBiotech = () => {
  return (
    <>
      <Banner />
      <We />
      <Discover />
      <Contact />
    </>
  );
};

export default LandingBiotech;
