import React from 'react';
import data from "../../data/landing/data-landing.json"
import "./we.css"

const We = () => {
    return (
        <>
            <div className='we'>
                <div className='we__container'>
                    <span className='we__container__text'>{data.we.text}</span>
                </div>
            </div>
        </>
    );
}

export default We;