import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import data from "../../data/landing/data-landing.json"
import "./contact.css"

const initialState = {
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
};

const Contact = () => {
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const clearState = () => setFormData({ ...initialState });

    const sendContactInfoToEmail = async () => {
        try {
            const result = await axios.post('https://email-backend-stack-gecko.vercel.app/send-email', {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                company: formData.company,
                message: formData.message,
            });
            console.log('result: ', result);
            Swal.fire({
                title: 'Email Sent Successfully',
                html: 'Thank you for contacting us! We have received your message and will get back to you as soon as possible.',
                icon: 'success',
                confirmButtonColor: "#3D4366",
                background: "linear-gradient(70.45deg, #CA1ED7 -24%, #5A3B7E 18.53%, #3D4366 61.56%, #437FBD 144.91%)",
                color: "#F1E9EC"
            })
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                confirmButtonColor: "#3D4366",
                background: "linear-gradient(70.45deg, #CA1ED7 -24%, #5A3B7E 18.53%, #3D4366 61.56%, #437FBD 144.91%)",
                color: "#F1E9EC"
            })
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendContactInfoToEmail();
        clearState();
    };

    return (
        <>
            <div id="contact__landing" >
                <div className="contact__content" data-aos="fade-up">
                    <h2 className='contact__title'>{data.contact.title}</h2>
                    <p className='contact__paragraph'>{data.contact.text}</p>
                    <form className="contact__form" name="sentMessage" validate="true" onSubmit={handleSubmit}>
                        <div className='contact__form__input'>
                            <div className='contact__form__items'>
                                <div className="contact__form__item">
                                    <label className="contact__form__label" htmlFor="name">full name</label>
                                    <input className='contact__form__input' type="text" id="name" name="name" required value={formData.name} onChange={handleChange} />
                                </div>
                                <div className="contact__form__item">
                                    <label className="contact__form__label" htmlFor="name">email</label>
                                    <input className='contact__form__input' type="email" id="email" name="email" required value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                            <div className='contact__form__items'>
                                <div className="contact__form__item">
                                    <label className="contact__form__label" htmlFor="phone">phone number</label>
                                    <input className='contact__form__input' type="tel" inputMode="numeric" id="phone" name="phone" required value={formData.phone} onChange={handleChange} />
                                </div>
                                <div className="contact__form__item">
                                    <label className="contact__form__label" htmlFor="company">company name</label>
                                    <input className='contact__form__input' type="text" id="company" name="company" required value={formData.company} onChange={handleChange} />
                                </div>
                            </div>
                            <div className='contact__form__textarea'>
                                <label className="contact__form__label" htmlFor="message">brief description of your business and needs</label>
                                <textarea className='contact__form__message' id="message" name="message" required value={formData.message} onChange={handleChange} />
                            </div>
                        </div>
                        <input className='contact__form__button' type='submit' value="send" />
                    </form>
                </div>
            </div>
        </>
    );
}

export default Contact;
