export const Header = (props) => {
  const { data } = props;
  const title = data
    ? data.title.split('\n').map((text, index) => (
        <h1 key={index}>
          {text}
          <br />
        </h1>
      ))
    : 'Loading';
  const paragraph = data ? data.paragraph : 'Loading';

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className=" intro-text">
                {title}
                <p>{paragraph}</p>
                <hr className="line" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
