import React from 'react';
import data from '../../data/landing/data-landing.json';
import './banner.css';

const title = 'Biotech Digital Innovation';

const paragraph =
  'Transform your biotech operations with peace of mind as each project phase is meticulously managed and executed. Embrace innovation and secure your future with our expertly tailored digital solutions.';

const Banner = () => {
  return (
    <>
      <div className="banner">
        <div className="banner__video">
          <video
            src={'./video/background-video.mp4'}
            alt={data.banner.image.alt}
            autoPlay
            loop
            muted
          />
        </div>
        <div className="banner__section__content">
          <div className="banner__section__textImg">
            <div className="banner__section__text">
              <h1 className="banner__title">{title}</h1>
              <p className="banner__text">{paragraph}</p>
            </div>
            {/* <img
              className="banner__img"
              src={'./img/landing/biotech.jpg'}
              alt={data.banner.image.alt}
              style={{ width: '26%', height: 'auto' }}
            /> */}
          </div>
          <div className="banner__section__button">
            <a href="#contact__landing">
              <div className="banner__button">
                <span className="banner__button__text">{data.banner.button}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
